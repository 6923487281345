import React from "react";

import BannerCarousel from "./Banner-Carousel";
import style from "./Banner.module.scss";

export default function Banner() {
  return (
    <div className={style.wrapper}>
      <div className={style.bannerContent}>
        <div className={style.left}>
          <div className={style.mediumText}>Member of Parliament</div>
          <div className={style.bigText}>Official Services</div>
          <div className={style.greyText}>Narasaraopet, Andhra Pradesh</div>
          <button>Know More</button>
        </div>
        <div className={style.right}>
          <BannerCarousel />
        </div>
      </div>
    </div>
  );
}
