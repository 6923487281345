import React from "react";
import style from "./Services.module.scss";

import centralMinistry from "./_assets/services/central_ministry.webp";
import constituency from "./_assets/services/constituency.webp";
import jobReferral from "./_assets/services/job_referral.webp";
import medical from "./_assets/services/medical.webp";
import transferPosting from "./_assets/services/transfer_posting.webp";
import wishes from "./_assets/services/wishes.webp";

const SERVICES = [
  {
    id: 1,
    name: "Job Referrals",
    image: jobReferral,
    link: "http://bit.ly/klmslogin",
  },
  {
    id: 2,
    name: "Constituency Governance & Civic Problems",
    image: constituency,
    link: "http://bit.ly/klmslogin",
  },
  {
    id: 3,
    name: "PM/CM Relief Funds (Medical Support)",
    image: medical,
    link: "http://bit.ly/klmslogin",
  },
  {
    id: 4,
    name: "Representation to Central Ministries",
    image: centralMinistry,
    link: "http://bit.ly/klmslogin",
  },
  {
    id: 5,
    name: "Wishes & Condolences",
    image: wishes,
  },
  {
    id: 6,
    name: "Transfer Postings",
    image: transferPosting,
    link: "http://bit.ly/klmslogin",
  },
];

export default function Services() {
  function handleCardClick(link) {
    window.open(link);
  }

  return (
    <div className={style.wrapper}>
      <p className={style.heading}>
        Connect with Honourable <span>Sri Krishna Devarayalu Lavu</span> (MP, Narasaraopet)
      </p>
      <div className={style.cards}>
        {SERVICES.map((service) => (
          <div
            className={style.card}
            key={service.id}
            onClick={service.link ? handleCardClick.bind(this, service.link) : null}
            style={!service.link ? { pointerEvents: "none" } : null}
          >
            <img src={service.image} alt="" />
            <p>{service.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
}
