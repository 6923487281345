import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Pagination } from "swiper/core";

import style from "./Banner-Carousel.module.scss";

import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";

import Carousel1 from "./_assets/carousel-1.png";
import Carousel2 from "./_assets/carousel-2.png";

SwiperCore.use([Pagination, Autoplay]);

export default function () {
  return (
    <Swiper pagination={true} className={style.wrapper}   autoplay={{
      delay: 2500,
      disableOnInteraction: false,
    }}>
      <SwiperSlide>
        <div className={style.slide}>
          <img src={Carousel1} alt="" />
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className={style.slide}>
          <img src={Carousel2} alt="" />
        </div>
      </SwiperSlide>
    </Swiper>
  );
}
