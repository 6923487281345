import React from "react";
import style from "./App.module.scss";

import Navbar from "./Navbar";
import Banner from "./Banner";
import Services from "./Services";
import Footer from "./Footer";

export default function () {
  return (
    <div className={style.wrapper}>
      <Navbar />
      <Banner />
      <Services />
      <Footer />
    </div>
  );
}
