import React from "react";
import style from "./Footer.module.scss";

import Logo from "./_assets/logo.png";
import Facebook from "./_assets/fb.svg";
import Twitter from "./_assets/twitter.svg";
import Whatsapp from "./_assets/wa.svg";

export default function () {
  return (
    <div className={style.wrapper}>
      <div className={style.footerContent}>
        <div className={style.logoContainer}>
          <img src={Logo} alt="" />
          <span>YSR Congress</span>
        </div>
        <div className={style.linksContainer}>
          <div className={style.text}>Connect with us</div>
          <div className={style.images}>
            {[Whatsapp, Facebook, Twitter].map((item) => (
              <div className={style.image}>
                <img src={item} alt="social_media_logo" />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
