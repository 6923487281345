import React from "react";
import style from "./Navbar.module.scss";

import logo from "./_assets/logo.png";

export default function Navbar() {
  function handleLoginClick() {
    window.open("http://bit.ly/klmslogin");
  }
  return (
    <div className={style.wrapper}>
      <div className={style.navbarContent}>
        <div className={style.logo}>
          <img src={logo} alt="" />
          <p>YSR Congress</p>
        </div>
        <div className={style.links}>
          <p>Contact Us</p>
          <button onClick={handleLoginClick}>Login/Signup</button>
        </div>
      </div>
    </div>
  );
}
